@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: 'futuraBold';
	src: url('../public/fonts/futura_bold_font.ttf');
}

@font-face {
	font-family: 'futuraLight';
	src: url('../public/fonts/futura_light_font.ttf');
}

@font-face {
	font-family: 'futuraMedium';
	src: url('../public/fonts/futura_medium_bt.ttf');
}

@font-face {
	font-family: 'futuraBlack';
	src: url('../public/fonts/futura_ extra_black_font.ttf');
}

@layer base {
	html {
		font-family: 'futuraLight', system-ui, sans-serif;
	}

	input {
		outline: none;
	}

	* {
		margin: 0;
		padding: 0;
	}

	.homeCarousel > div.absolute {
		bottom: 0.25rem;
	}

	.homeCarousel > div.absolute > span {
		width: 0.5rem;
		height: 0.5rem;
	}

	.arrow.slick-prev:before{
		content: "";
	}

	.arrow.slick-next:before{
		content: "";
	}
}
